section.full {
    width:100%;
    box-sizing: border-box;
    border-left: 0;
    border-right: 0;
    padding: 6rem 0;
}

section.big {
    padding: 12rem 0.5rem;
}

section.blocks {
    border-left: 0;
    border-right: 0;
    box-sizing: border-box;
    border-left: 0;
    border-right: 0;
    text-align: center;
    color:#fff;
}

section.blocks {
    padding: 7rem 0.5rem;
}


section.blocks h1 {
    padding-bottom: 2rem;
    border-bottom: 1px solid #fff;
    width: 60%;
    margin: 0 auto;
}

section.blocks h1.alt {
    padding-bottom: 1rem;
    border-bottom: 0;
}

section.blocks article{
    margin: 2rem 0;
}


section.blocks .spacer {
    background-color: #fff;
    padding: 0;
    margin: 0 auto;
    height: 100%;
    box-sizing: content-box;
    max-width: 0px;
}

section img {
    width: 100%;
}

section .img-container {
    text-align: center;
}

.cardblock-container {
    padding: 0 !important;
    padding-bottom: 8rem !important;
}

.cardblock-container .container {
    padding: 1rem 0;
}

section.tiny {
    padding: 1rem !important;
}

.cardbox {
  padding: 1rem !important;
}

.cardbox article {
  background-color: #fff;
  padding: 2rem;
}


@media (max-width: 768px) {

    section.blocks article {
        padding: 1rem 0.5rem;
    }
    section.blocks h1 {
        width: 100%;
    }
    section.blocks .spacer {
        display: none;
    }
/*

    section.blocks, section.full, section.big {
        padding: 3rem 0.5rem;
    }

    section.full article {
        padding: 2rem 0.5rem;
    }

    section.full article:first-child {
        padding-top: 6rem;
    }

    section.full article:last-child {
        padding-bottom: 6rem;
    }    

    article.cardblock {
        padding-bottom: 0; 
        margin: 0 !important;
        padding: 0 !important;
    }*/
}