.cardblock {
    padding-left: 0;
    background-color: #fff;
    margin-left: 0;
    text-align: left;
    margin-bottom: 2rem;
}

.cardblock h3 {
    margin-bottom: 0;
}

.cardblock {
    box-shadow: 0 .1rem .4rem rgba(255, 100, 100, 0.3);
    cursor: pointer;
}

.cardblock:hover {
    box-shadow: 0 .1rem .4rem rgba(255, 100, 100, 0.5);
}

.cardleft {
    color: #fff;
}

.cardleft, .cardright {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.cardleft ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.cardleft ul li {
    padding: 1rem 0.5rem;
}

.cardright {
    text-align: center;
}

.subtext {
    padding: 1rem 4rem;
    padding-bottom: 0;
}

section.blocks article.cardblock {
    padding: 0;
}


@media (max-width: 768px) {
    .subtext  {
        padding: 0;
    }

    .cardblock-container {
        margin: 0;
    }

    .cardblock-container article {
        margin: 1rem 0;
        padding: 0rem !important;
    }
}