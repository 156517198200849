a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
  font-size: 1.1rem;
}

.nav-link {
  text-transform: lowercase;
  padding-left: 2.5rem !important;
  padding-right: 0 !important;
  font-size: 1rem;
}

.navbar {
  letter-spacing: 1px;
  padding: 1.5rem 0;
  position: fixed;
  z-index: 999;
  width: 100%;
  top: 0;
  border-bottom: 1px solid #aaa;
  -webkit-box-shadow: 0px 0px 5px 1px rgba(41,41,41,.25);
  -moz-box-shadow: 0px 0px 5px 1px rgba(41,41,41,.25);
  box-shadow: 0px 0px 5px 1px rgba(41,41,41,.25);
}

.navbar-brand img {
  height: 20px;
  margin-top: -3px;
}

body {
  padding-top: 5.4rem;
}

.navbar-toggler {
  border: 0 !important;
  outline: none !important;
}

@media (max-width: 768px) {
  .nav-link {
    font-size: 1rem;
    text-align: center;
    padding-left: 0 !important;
    border-top: 1px solid #eee;
    padding: 1rem;
  }

  a.navbar-brand {
    padding-left: 1rem !important;
  }
}