#filter {
    text-align: left;
    padding: 3rem 1.5rem;
}

@media (max-width: 768px) {
    #filter {
        padding: 2rem 0;
    }

    #filter article {
        padding: 0;
    }
}