html, body {
  position: relative;
  min-height: 100vh;
  margin: 0;
  font-family: 'Rubik', sans-serif;
}

#main {
  min-height: 100vh;
  margin-bottom: -50px;
  margin-top: -6.1rem;
  padding-top: 6rem;
}

.pre {
  white-space: pre-wrap;;
}

.grecaptcha-badge { 
  opacity:0;
}

.pill-list {
  padding: 0;
  margin-bottom: 0;
}

.pill-list li {
  padding: 0.25rem 2rem;
  margin-right: 0.5rem;
  display: inline-block;
  list-style: none;
  background-color: #eee;
  color: #333;
  border-radius: 10px;
  font-size: 0.8rem;
}

p {
  padding-top: 0.5rem;
  display: block;
  width: 100%;
  line-height: 1.75rem;
}

html {
  font-size: 15px;
  line-height: 200% !important;
}

ul.spaced li {
  padding-top: 1rem;
}

article li {
  padding: 1rem 0;
}

.btn, .btn:visited {
  width: 100%;
  border-radius: 0;
  border: 0;
  color: #fff;
  margin-top: 1rem;
  font-weight: bold;
}

.btn-primary {
  color: #fff !important;
  cursor: pointer;
}

label {
  padding-bottom: 0;
  margin-bottom: -2px;
}

.form-check-label {
  font-weight: normal;
}

.btn-link {
  cursor: pointer;
}

.fas {
  vertical-align: middle;
}

.btn-primary:hover {
  border: 0;
}

.disable-user-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.offer-respond {
  width: 100%;
}
a, a:hover, a:visited {
  color: inherit;
  text-decoration: inherit;
}
@media (max-width: 768px) {
  article {
    padding-bottom: 4rem;
  }

  article ul {
    padding-left: 1.5rem;
  }
}