#jumbotron {
    z-index: 0;
    position: relative;
    padding-bottom: 20rem !important;
    padding-top: 10rem !important;
    color: #fff ;
    margin: 0;
    overflow: hidden;
}

#jumbotron-overlay {
    z-index: -1;
    width: 100%;
    position: absolute;
    opacity: 0.5;
    background-image: url("overlay.png");
    top: 0;
    bottom: 0;
    left: 0;
}

#jumbotron-image {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    z-index: -2;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
}

.image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden
}

.image-container img {
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%;
}

#jumbotron .button {
    cursor: pointer;
    margin-top: 3rem;
    border: 0;
    margin-right: 2rem;
    color: #fff;
    padding: 1rem 4rem;
    cursor: pointer;
    z-index: 2;
}

#jumbotron.small {
    padding: 6rem 0 !important;
}

#jumbotron.small img {
    margin-top: -15%;
}

#button-container {
    margin-top: 4rem;
}

#arrow-container {
    display: none;
}

@media (max-width: 768px) {
    #jumbotron .button {
        display: block;
        width: 100%;
        text-align: center;
        padding: 1rem 0;
        margin: 1rem 0;
    }

    #jumbotron {
        padding: 2rem 0 !important;
        height: 90vh;
    }

    #jumbotron.small {
        height: auto;
    }

    #arrow-container {
        display: block;
        position: absolute !important;
        bottom: 3rem !important;
        text-align: center;
        width: 100%;
        opacity: 0.8;
        margin-left: -15px;
    }

    .image-container img {
        margin-top: -35% !important;
    }
}