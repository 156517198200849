.close-modal {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    padding: 1rem;
    font-size: 1rem;
}

.ReactModal__Content {
    width: 50%;
    margin: 0 auto;
    margin-top: 8rem;
    overflow: scroll;
}


@media (max-width: 1200px) {
    .ReactModal__Content {
        left: 1% !important;
        right: 1% !important;
        margin-left: 0 !important;
        width: 98%;
        margin-top: 3rem;
    }
  }