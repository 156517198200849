footer {
    color: #fff;
    bottom: 0;
    width: 100%;
    line-height: 60px;
}
footer, .push {
  height: 60px;
}

footer .container {
    text-align: right;
}

footer a {
    margin-left: 10px;
    right: 0;
}

footer a:hover {
    margin-left: 10px;
    right: 0;
}