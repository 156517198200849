/*

Colors:
#F7F9FB
#921515

*/

.dark, section.full.dark h1, section.blocks, .section.blocks h3{
    color: #F7F9FB !important;
}

section .MuiSlider-colorPrimary {
    color: #f00 !important;
}

section.dark button, section.dark button:hover {
    background-color: #f00 !important;
}

body, .form-control, .date-picker input {
    background-color: #F7F9FB !important;
}

.btn, #jumbotron .button, .cardleft, #jumbotron .button, .btn-primary:hover, .prim-bg, .dark, section.blocks, section.blocks h3, .inputfile + label:hover  {
    background-color: #921515 !important;
}

.form-control, .date-picker input, #opdracht h2 {
    border-color: #921515 !important;
}

.inputfile + label, .MuiSlider-root, section.full h3 , section.full h2 , section.full h1 {
    color: #921515 !important;
}

#jumbotron .button.secondary {
    background-color: #996161 !important;
}

.prim-bg {
    color: #F7F9FB;
}

.btn-warning {
    color: #333;
    font-weight: bold;
    background-color: #ffc107 !important;
}

.shortlist {
    background-color: #595 !important;
    color: #F7F9FB !important;
}

.clientSubmit {
    font-weight: bold !important;
    text-decoration: underline;
}

.ui.label {
    font-weight: normal;
}

footer {
    background-color: #333;
}

footer a {
    color: #F7F9FB;
}

footer a:hover {
    color: #fff;
}

.navbar {
    background-color: #fff;
}