.form-control {
    border: 0;
    border-bottom: 1px solid;
    padding: 0 0.5rem;
    border-radius: 0;
}

.date-picker input {
    border: 0;
    border-bottom: 1px solid;
    border-radius: 0;
}

label {
    vertical-align: middle;
    padding: 0.5rem 0;
    font-weight: bold;
}

.form-check-label {
    padding: 0;
}

.react-datepicker-wrapper {
    display: block !important;
}


.date-picker label {
    display: none;
}
.inputfile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}
.inputfile + label {
    padding: 0.5rem 1rem;
    font-weight: normal;
    background-color: #eee;
    display: inline-block;
}

.inputfile:focus + label,
.inputfile + label:hover {
    color: white !important;
}
.inputfile + label {
	cursor: pointer; /* "hand" cursor */
}

.form-check label {
    cursor: pointer;
    -webkit-user-select: none;
}

.form-check a, .form-check a:visited {
    color: #aaf !important;
    cursor: pointer !important;
}

.form-check a:hover{
    text-decoration: underline !important;
}


label {
    font-weight: none !important;
}