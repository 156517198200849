#opdracht .row > div {
    border-right: 1px solid #fff;
    padding: 1rem;
}

#opdracht dl .row {
    margin: 0 0.25rem;
    margin: 2rem 0;
}

#opdracht em p {
    margin-bottom: 1rem !important;
    color: #555;
}

#opdracht .row > div:last-child {
    border-right: 0px;
}

#opdracht h1, #opdracht h2, #opdracht h3, #opdracht h4 {
    padding: 0;
    margin: 0;
}

#opdracht h1 {
    padding-bottom: 0.75rem;
    margin: 0;
}

#opdracht h2 {
    font-size: 1.1rem;
    border-bottom: 1px solid;
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
}